var surveyWindowClosed;
var surveyPopupInstance;

function cancelSurvey() {
    surveyPopupInstance.hidePopupModal();
    surveyPopupInstance.setPopupCookie("cancel");

    triggerSurveyEvent("survey_canceled", surveyPopupInstance.popupDetailObj);
}

function openSurvey() {
    var surveyUrl = includeCustomSurveyVariables(surveyPopupInstance.popupDetailObj.surveyURL);
    var surveyWindow = window.open(surveyUrl, surveyPopupInstance.popupDetailObj.title, 'width=1000,height=700');
    surveyWindowClosed = setInterval(function () {
        if (surveyWindow.closed) {
            clearInterval(surveyWindowClosed);
            showTerminationPopup({ action: "surveyTermination", data: "survey_aborted" });
        }
    }, 500);

    surveyPopupInstance.hidePopupModal();
    triggerSurveyEvent("survey_opened", surveyPopupInstance.popupDetailObj);
}

function closeSurvey() {
    surveyPopupInstance.setPopupCookie("cancel");

    triggerSurveyEvent("survey_aborted", surveyPopupInstance.popupDetailObj);
}

function includeCustomSurveyVariables(surveyUrl) {
    var urlObj = new URL(surveyUrl);

    if (!urlObj.searchParams.has("urlimport")) {
        urlObj.searchParams.set("urlimport", 1);
    }

    var defaultQuestList = "PERSON_ID;IS_MEMBER";

    if (urlObj.searchParams.has("questlist")) {
        urlObj.searchParams.set("questlist", urlObj.searchParams.get("questlist") + ";" + defaultQuestList);
    } else {
        urlObj.searchParams.set("questlist", defaultQuestList);
    }

    if (!urlObj.searchParams.has("PERSON_ID")) {
        urlObj.searchParams.set("PERSON_ID", wawUser.personId);
    }

    if (!urlObj.searchParams.has("IS_MEMBER")) {
        urlObj.searchParams.set("IS_MEMBER", wawUser.isMember);
    }

    return urlObj.href;
}

$(document).ready(function () {
    var surveyIframe = $('#survey-iframe');
    surveyIframe.on('load', function () {
        showSurveyIframe();
    });

    if (siteMetaData.surveyContentItemIds.length > 0) {
        new bootstrap.Modal(document.getElementById('surveyPopup'));
        var surveyDetailsPromise = getSurveyDetails(siteMetaData.surveyContentItemIds);
        surveyDetailsPromise.then(
            function (surveyList) {
                surveyPopupInstance = new CustomPopup("#surveyPopup");
                surveyPopupInstance.displayPopup(surveyList);
            },
            function (error) {
                console.error(error);
            }
        );
    }
});


function getSurveyDetails(contentItemIds) {
    return new Promise((resolve, reject) => {
        let queryString = contentItemIds.map(item => `contentItemIds=${encodeURIComponent(item)}`).join('&');

        $.ajax({
            url: AppConfig_AppPaths_WebURL.value + '/survey?' + queryString,
            type: "GET",
            success: function (data) {
                resolve(data);
            },
            error: function (error) {
                reject(error);
            }
        });
    });
}

function triggerSurveyEvent(eventName, surveyDetail){
    gtag("event", eventName, {
        survey_id: surveyDetail.id,
        title: surveyDetail.title,
        page_url: window.location.href,
        person_id: wawUser.id,
        is_member: wawUser.memberStatus
    });

    try {
        window.appInsights.trackEvent({
            "name": eventName,
            "properties": {
                "SurveyId": surveyDetail.id,
                "Title": surveyDetail.title,
                "PageUrl": window.location.href,
                "PersonId": wawUser.id,
                "IsMember": wawUser.memberStatus
            }
        });
        window.appInsights.flush();
    }
    catch (ex) {

    }
}

function showTerminationPopup(event) {
    if (event.action === "surveyTermination") {
        onSurveyTermination(event.data);
    }
}

function handleMessage(event) {
    if (event.origin !== window.location.origin) {
        return;
    }

    if (event.data.action === "surveyTermination") {
        onSurveyTermination(event.data.data);
    }
}

window.addEventListener("message", handleMessage, false);

function onSurveyTermination(data) {
    clearInterval(surveyWindowClosed);
    new bootstrap.Modal(document.getElementById('surveyTerminationPopup'));
    var surveyTerminationModalEl = document.getElementById('surveyTerminationPopup')
    var surveyTerminationModal = bootstrap.Modal.getInstance(surveyTerminationModalEl);
    surveyTerminationModal.show();

    if (data == "survey_interrupted" || data == "survey_aborted") {
        $(".survey-termination-message").html(surveyPopupInstance.popupDetailObj.surveyInterruptedMessage)
    } else {
        $(".survey-termination-message").html(surveyPopupInstance.popupDetailObj.surveyCompletedMessage)
        surveyPopupInstance.setPopupCookie("submission");
    }

    triggerSurveyEvent(data, surveyPopupInstance.popupDetailObj);
}

function showSurveyIframe() {
    $("#survey-iframe").show();
    $(".iframe-loader").hide();
}